import "../styles/signup-dfy.scss"
import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { Helmet } from "react-helmet"
import { loadStripe } from "@stripe/stripe-js"
import { Link, navigate, graphql } from "gatsby"
// import favicon from "../images/favicon.ico"
import favicon from "../images/favicon.png"
// import Img from "gatsby-image"
import {
  CardElement,
  //   CardNumberElement,
  //   CardExpiryElement,
  //   CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js"
import GoTrue from "gotrue-js"
import firebase from "gatsby-plugin-firebase"
import Header from "../components/Header"
// import Header from "../components/BlueHeader"
// import Header from "../components/TealHeader"
// import { useFirebaseArsenal, useAuth } from "../lib/firebase/firebaseCentral"
import { StaticImage } from "gatsby-plugin-image"

import CSSLoader from "../components/Loaders/CSSLoader"

const StripeCard = ({
  name,
  email,
  password,
  firebase,
  identity,
  user,
  setPaymentComplete,
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [error, setError] = useState(null)
  const [subscriptions, setSubscriptions] = useState([])

  const [processing, setProcessing] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(null)
  // useEffect(() => {
  //   document.documentElement.style.setProperty(
  //     "--scrollbar-width",
  //     window.innerWidth - document.documentElement.clientWidth + "px"
  //   )
  // }, [])
  const getSubscriptions = async () => {
    if (email && firebase) {
      //   const subs = await firebase.listSubscriptions({ userId: email })
      const listSubscriptionsCallable = await firebase
        .functions()
        .httpsCallable("listSubscriptions")
      const subs = await listSubscriptionsCallable({
        userId: email,
      })
      // console.log("subs", subs)
      setSubscriptions(subs.data.data)
    }
  }

  // Cancel subscription
  const cancelSubscription = async id => {
    setProcessing(true)
    const cancelSubscriptionCallable = await firebase
      .functions()
      .httpsCallable("cancelSubscription")
    await cancelSubscriptionCallable({
      userId: email,
      subscriptionId: id,
    })
    // await firebase.cancelSubscription({
    //   userId: email,
    //   subscriptionId: id,
    // })
    toast.success("Your previous subscription has been canceled!")
    await getSubscriptions() // rehydrate subscriptions
    setProcessing(false)
  }

  const handleSubmit = async event => {
    // event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (error) {
      // console.log("ererrorerrorror", error)
      elements.getElement("card").focus()
      return
    }

    // if (cardComplete) {
    //   setProcessing(true)
    // }
    setProcessing(true)

    // console.log("name", name)
    // console.log("email", email)

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: { name, email },
    })

    // setProcessing(false)

    if (payload.error) {
      setError(payload.error)
      // console.log("payload.error", payload.error)
    } else {
      setPaymentMethod(payload.paymentMethod)
      //   console.log("payload", payload)
      //   console.log("{name,email,password}", { name, email, password })
    }

    try {
      // console.log("[...]", [email, payload.paymentMethod.id, name])
      // Create Subscription on the Server
      const createSubscriptionCallable = await firebase
        .functions()
        .httpsCallable("createSubscription")

      // console.log("2", [email, payload.paymentMethod.id, name])
      const subscription = await createSubscriptionCallable({
        userId: email,
        name,

        plan: process.env.GATSBY_PUBLIC_STRIPE_INHOUSE_BASIC,
        // plan: "price_1KZoJbJ1tYytnqOaOb39hLIP",
        //   paymentMethod: wallet?.[0]?.id
        paymentMethod: payload.paymentMethod.id,
      })

      const { latest_invoice } = subscription.data

      // console.log("subscription.data", subscription)

      // At this point, if there was an error with the initial invoice/payment intent,
      // then no updates to firestore would have taken place.
      // The below error handling is one way we can let the user know
      // the reason as to why their payment did not go through.
      if (latest_invoice.payment_intent) {
        const { client_secret, status } = latest_invoice.payment_intent
        // stripe will attempt to make a charge
        if (status === "requires_action") {
          const { error: confirmationError } = await stripe.confirmCardPayment(
            client_secret
          )
          if (confirmationError) {
            // console.error(confirmationError)
            alert("unable to confirm card")
            return
          }
        }

        /*
         * If we only want 1 subscription active at a time, then also cancel current subscription *
         */
        // if (subscriptions.length > 0) {
        //   cancelSubscription(subscriptions?.[0]?.id)
        // }

        // success
        //   alert('You are subscribed!');
        // toast.success("You are subscribed!")
        // getSubscriptions()

        identity
          .signup(email, password)
          .then(response => {
            // console.log("response", response)
            toast.success("You are subscribed!")
            // toast.success('Something went wrong when creating your account.',error)
          })
          .catch(error => {
            toast.error(
              "Something went wrong when creating your account.",
              error
            )
          })

        const user = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)

        if (!user) {
          // console.warn("something went wrong")
          toast.error(
            "Something went wrong when creating your account. Rest assured, you did not get charged."
          )
          return
        } else {
          const profile = firebase.auth().currentUser
          await profile.updateProfile({
            displayName: name,
          })
        }

        // change state
        setPaymentComplete(true)
      }

      setProcessing(false)
    } catch (err) {
      setProcessing(false)
      // console.log("err", err)
      toast.error(`Unsuccessful`)
    }
  }

  return (
    // <form onSubmit={handleSubmit} className="register__card-container">
    <div className="register__card-container">
      <div className="register__card-heading">Add Card Details</div>
      <div className="register__card">
        {/* <CardElement style={{ color: "#fff" }} /> */}
        {/* <CardNumberElement */}
        <CardElement
          //  onChange={onChange}
          options={{
            iconStyle: "solid",
            style: {
              base: {
                // iconColor: "#c4f0ff",
                color: "#fff",
                fontWeight: 500,
                fontFamily: "Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                autoFill: "#fff",
                ":-webkit-autofill": {
                  color: "#fff",
                },
                "::placeholder": {
                  color: "#fff",
                },
              },
              invalid: {
                iconColor: "#ffc7ee",
                color: "#ffc7ee",
              },
            },
          }}
        />
      </div>
      <button
        // style={{ backgroundColor: `${processing ? "#333" : "#272727"}` }}
        disabled={processing || !stripe}
        onClick={handleSubmit}
        className="register__confirm"
      >
        {processing ? "Processing" : "Confirm"}
      </button>
    </div>
  )
}

export default function DFYBasic() {
  const [user, setUser] = useState(null)
  const [identity, setIdentity] = useState(null)

  const [step, setStep] = useState("one")
  const [stripeObj, setStripeObj] = useState({})
  const [paymentComplete, setPaymentComplete] = useState(false)
  const [accountDetails, setAccountDetails] = useState({
    name: "",
    email: "",
    password: "",
  })

  useEffect(() => {
    const auth = new GoTrue({
      APIUrl: "https://www.berserkers.dev/.netlify/identity",
      audience: "",
      // setCookie(optional): set to be false by default. If you wish to implement the remember me functionality, set the value to be true.
      setCookie: false,
    })
    // console.log("auth", auth);
    setIdentity(auth)
  }, [])

  useEffect(() => {
    if (identity?.currentUser()?.email) {
      if (
        identity &&
        identity.currentUser &&
        identity.currentUser() &&
        identity.currentUser().email
      ) {
        const e = identity.currentUser().email
        // console.log("e", e);
        getUser(e)
      }

      async function getUser(e) {
        // console.log("e", e);
        const userRef = firebase
          .firestore()
          .collection("users")
          // .doc(identity.currentUser().email);
          .doc(e)
        const userDoc = await userRef.get()
        const userData = await userDoc.data()
        setUser(userData)
      }
    }
  }, [identity])
  console.log("user", user)

  // useEffect(() => {
  //   if (profile?.email) {
  //     navigate("/dashboard")
  //   }
  // }, [profile])

  // console.log("firebase", firebase?.auth?.createUserWithEmailAndPassword)
  console.log("stripeObj!", stripeObj)

  useEffect(() => {
    let stripePromise
    const getStripe = () => {
      if (!stripePromise) {
        stripePromise = loadStripe(
          "pk_test_51JPrYxJ1tYytnqOaHXCvc4aK8UD2tBQ5mLCkQnXXRrkNW25MPpANcH8gtl3fM2R3YrQXk6TNzQzdEipkYRzdGsxJ00MnD6g6Df"
        )
      }
      return stripePromise
    }

    async function handleStripe() {
      const stripe = await getStripe().catch(err => console.warn("err", err))
      if (stripe) {
        // console.log("stripe", stripe)
        setStripeObj(stripe)
      }
    }

    handleStripe()
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm()

  const onSubmitAccountDetails = data => {
    // console.log(data)
    // console.log("getValues", getValues())

    setAccountDetails(getValues())
    setStep("two")
  }
  // console.log("errors", errors)
  return (
    <>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={favicon} />
        <title>Done For You | Basic</title>
      </Helmet>
      <div className="package">
        <>
          <div className="dfy-img-left">
            {/* <Img fluid={data.one.childImageSharp.fluid} /> */}
            {/* <StaticImage src="../images/abstract_1.png" alt="" /> */}
            <StaticImage src="../images/abstract_5.png" />
          </div>
          <div className="dfy-img-right">
            {/* <Img fluid={data.two.childImageSharp.fluid} /> */}
            {/* <StaticImage src="../images/abstract_2.png" alt="" /> */}
            <StaticImage src="../images/abstract_4.png" />
          </div>
        </>

        {step === "one" && !user?.email && !paymentComplete && (
          <>
            <form
              className="register"
              onSubmit={handleSubmit(onSubmitAccountDetails)}
            >
              <div className="register__header">
                <div>Step 1 of 2</div>
                <div>Create Account</div>
              </div>
              <div className="register__container">
                <label className="register__label" htmlFor="name">
                  Name
                </label>
                <input
                  className="register__input"
                  id="name"
                  type="text"
                  // placeholder="Enter your email"
                  {...register("name", {
                    required: { value: true, message: "name is required" },
                    minLength: { value: 3, message: "Min chars is 3" },
                    maxLength: {
                      value: 30,
                      message: "Max length is 30 characters",
                    },
                  })}
                />
                {errors.name && <span>{errors.name.message}</span>}
                <label className="register__label" htmlFor="email">
                  Email
                </label>
                <input
                  className="register__input"
                  id="email"
                  type="email"
                  // placeholder="Enter your email"
                  {...register("email", {
                    required: { value: true, message: "email is required" },
                    minLength: { value: 5, message: "Min chars is 5" },
                    maxLength: {
                      value: 300,
                      message: "Max length is 300 characters",
                    },
                    pattern: {
                      value: /\S+@\S+.\S+/,
                      message: "Entered value does not match email format",
                    },
                  })}
                />
                {errors.email && <span>{errors.email.message}</span>}

                <label className="register__label" htmlFor="password">
                  Password
                </label>
                <input
                  className="register__input"
                  id="password"
                  type="password"
                  // placeholder="Enter your password"
                  {...register("password", {
                    required: { value: true, message: "password is required" },
                    minLength: { value: 9, message: "Min chars is 9" },
                    maxLength: {
                      value: 25,
                      message: "Max length is 25 characters",
                    },
                  })}
                />
                {errors.password && <span>{errors.password.message}</span>}
                <label className="register__label" htmlFor="password">
                  Confirm Password
                </label>
                <input
                  className="register__input"
                  id="confirmPassword"
                  type="password"
                  // placeholder="Confirm your password"
                  {...register("confirmPassword", {
                    required: { value: true, message: "Confirm your password" },
                    minLength: { value: 9, message: "Min chars is 9" },
                    maxLength: {
                      value: 25,
                      message: "Max length is 25 characters",
                    },
                    validate: value =>
                      value === watch("password") || "Passwords don't match.",
                  })}
                />
                {errors.confirmPassword && (
                  <span>{errors.confirmPassword.message}</span>
                )}

                <button
                  type="submit"
                  className="register__confirm"
                  // onClick={() => setStep("two")}
                >
                  Next Step
                </button>
              </div>
            </form>
          </>
        )}

        {/* {step === "two" && ( */}
        {(step === "two" || user?.email) && !paymentComplete && (
          <>
            <form
              className="register"
              onSubmit={handleSubmit(onSubmitAccountDetails)}
            >
              {user?.email ? (
                <div
                  style={{ justifyContent: "center" }}
                  className="register__header"
                >
                  <div>Already Purchased</div>
                </div>
              ) : (
                <div className="register__header">
                  <div
                    onClick={() => setStep("one")}
                    className="register__back-arrow"
                  >
                    <svg
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 416 416"
                    >
                      <path d="M208 0C93.13 0 0 93.13 0 208s93.13 208 208 208 208-93.13 208-208S322.87 0 208 0zm35.31 292.69a16.05 16.05 0 015 11.4 16.23 16.23 0 01-1.18 6.23 16.06 16.06 0 01-8.79 8.79 16 16 0 01-17.63-3.8l-96-96a16 16 0 010-22.62l96-96a16 16 0 0122.62 22.62L158.65 208z" />
                    </svg>
                  </div>
                  <div>Payment Details</div>
                </div>
              )}
              <div className="register__top-content">
                <div className="register__text">Total price per month</div>
                <div className="register__price">
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 597 158"
                  >
                    <path
                      className="cls-1"
                      d="M509.39 302.83c0-19.55-14.91-27.63-28.54-32.52l-12.35-4.67c-8.74-3-11.51-7-11.51-11.69 0-5.31 4.48-9.56 11.93-9.56 6 0 11.72 2.76 14.7 11.69l22.38-5.74c-3-14-13.42-24.23-28.33-27.2V204h-18.33v18.7c-17 2.77-30 14-30 31 0 13.39 7.66 25.07 26 31.66l13.63 5.1c8.73 3.19 12.14 7 12.14 12.33 0 7.86-7 11.47-14.27 11.47-10.86 0-16.18-7.44-18.31-14.66l-22.79 6.16c3 17.85 15.76 28.26 33.65 30.6v18.49h18.31V336c18.06-3.21 31.69-14.47 31.69-33.17zM631.5 296h-16v-92h-29.16l-63.89 92v25.1h63.89v33.79h29.18V321.1h16zm-80.08 0l34.92-52.92V296zM696.9 201.89c-26.83 0-51.11 18.27-51.11 48.66 0 28.05 20.44 46.54 44.08 46.54a49.67 49.67 0 009.59-1.06l-43 58.86h32.37l36-49.94c9.8-13.17 22.79-30 22.79-53.34-.03-31.45-23.88-49.72-50.72-49.72zm-.21 71.19c-14.27 0-23.86-10.2-23.86-23.17s9.59-23.16 23.86-23.16c14 0 23.64 10.2 23.64 23.16s-9.59 23.17-23.64 23.17zM814 201.89c-26.84 0-51.12 18.27-51.12 48.66 0 28.05 20.45 46.54 44.09 46.54a49.57 49.57 0 009.58-1.06l-43 58.86h32.35l36-49.94c9.79-13.17 22.79-30 22.79-53.34 0-31.45-23.86-49.72-50.69-49.72zm-.22 71.19c-14.26 0-23.85-10.2-23.85-23.17s9.59-23.16 23.85-23.16c14.06 0 23.65 10.2 23.65 23.16s-9.59 23.17-23.65 23.17zM897.88 274.89a8.23 8.23 0 10-8.19-8.23 8.08 8.08 0 008.19 8.23zM938.83 196.89c-13.41 0-25.54 9.19-25.54 24.47 0 14.1 10.22 23.4 22 23.4a24.42 24.42 0 004.79-.54l-21.5 29.6h16.18l18-25.11c4.89-6.62 11.39-15.06 11.39-26.82.02-15.81-11.9-25-25.32-25zm-.1 35.79c-7.13 0-11.92-5.12-11.92-11.64s4.79-11.65 11.92-11.65a11.65 11.65 0 110 23.29zM997.35 196.89c-13.41 0-25.54 9.19-25.54 24.47 0 14.1 10.22 23.4 22 23.4a24.42 24.42 0 004.79-.54l-21.5 29.6h16.18l18-25.11c4.89-6.62 11.39-15.06 11.39-26.82.02-15.81-11.9-25-25.32-25zm-.1 35.79c-7.13 0-11.92-5.12-11.92-11.64s4.79-11.65 11.92-11.65a11.65 11.65 0 110 23.29z"
                      transform="translate(-425.69 -196.89)"
                    />
                  </svg>
                </div>
                <div className="register__text">
                  7-day, risk-free, money-back guarantee!
                </div>
              </div>

              {Object.keys(stripeObj).length > 0 &&
                firebase &&
                ((accountDetails.password === accountDetails.confirmPassword &&
                  !errors.name &&
                  !errors.email &&
                  !errors.password) ||
                  user?.email) && (
                  <Elements stripe={stripeObj}>
                    <StripeCard
                      user={user || null}
                      identity={identity}
                      firebase={firebase}
                      password={accountDetails.password}
                      email={accountDetails.email}
                      name={accountDetails.name}
                      setPaymentComplete={setPaymentComplete}
                    />
                  </Elements>
                )}
              {/* <button type="submit" className="register__confirm">
                Confirm
              </button> */}
            </form>
          </>
        )}

        {paymentComplete && (
          <div className="register">
            <div
              style={{ justifyContent: "center", width: "100%" }}
              className="register__header"
            >
              <div>
                {/* <b>Purchase Complete</b> */}
                Purchase Complete
              </div>
              {/* <div>Create Account</div> */}
            </div>
            <div
              style={{ alignItems: "center" }}
              className="register__container"
            >
              <div className="register__purchased-header">Let's do this</div>
              <div className="register__purchased-header">
                {accountDetails?.name}
                {accountDetails?.name ? "!" : ""}
              </div>

              <div className="register__purchased-p">
                Thank you for allowing us to help you supercharge your business
                forward.
              </div>
              <div className="register__purchased-p">
                For instructions on what to do next, you can expect an email{" "}
                {accountDetails?.email ? `sent to` : ""}{" "}
                <span className="register__purchased-highlight">
                  {accountDetails?.email ? `${accountDetails.email}` : ""}
                </span>{" "}
                from{" "}
                <span className="register__purchased-highlight">
                  hello@berserkers.dev
                </span>{" "}
                within the hour. Be sure to check your spam folder, updates
                folder, promotions folder, etc.
              </div>
              <div className="register__purchased-p">
                If you don’t see anything after an hour, please reach out at
                <span className="register__purchased-highlight">
                  {" "}
                  hello@berserkers.dev
                </span>{" "}
                and we’ll sort it out.
              </div>
              <div className="register__purchased-p">
                To manage your subscriptions, you can navigate to your{" "}
                <Link to="/dashboard">dashboard</Link>
              </div>
              <div className="register__purchased-p">
                For login, please enter the email and password you used during
                this registration process.
              </div>
              <div className="register__purchased-p">
                Now let's grow your business!
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
